#team {
    #team_wrapper {
        img {
            // border-radius: 50px;
            mask-image: linear-gradient(to top, rgba(255, 200, 61, 0) 0%, rgba(255, 200, 61, 1) 60%);

            &:hover {
                // transform: scale(1.1);
                transition: all 0.3s ease-in-out;
                // box-shadow: 0px 0px 70px 15px $orange;
            }
        }
    }

    .team-body {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
}