@import "_theme-variables.scss";

#research {
    min-height: 100vh;

    .nav {
        --bs-nav-link-color: #000;
        --bs-nav-link-padding-x: 1.5rem;
        --bs-nav-link-padding-y: 0.2rem;
        font-size: 14px;
        font-weight: 500;
        
    }

    .nav-pills {
        gap: 15px;
        --bs-nav-pills-link-active-color: #fff;
        --bs-nav-pills-link-active-bg: #532482;
        --bs-nav-link-hover-color: #532482;
        --bs-nav-pills-border-radius: 999px;
        

        .nav-link {
            border: 1px solid $purple;

        }
    }

    .card-title {
        line-height: 1.6rem;
    }

    .desc {
        height: 77px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    img {
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }
}