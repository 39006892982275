#ai-staking {
    .ai-staking-img-first {
        transition: all .3s ease;
        object-fit: cover;
        position: relative;
        height: 350px;
        width: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .ai-staking-img {
        transition: all .3s ease;
        object-fit: cover;
        position: relative;
        height: 250px;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .crypto-pd {
        padding: 3rem;
    }

    .others-pd {
        padding: 1.5rem;
    }

    @media only screen and (max-width: 768px) {
        .ai-staking-img-first {
            height: 250px !important;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0 !important;
        }

        .crypto-pd {
            padding: 1.5rem !important;
        }
    }


    @media screen and (max-width: 576px) {
        .apexis-ai-banner-wrapper {
            top: 65%;
            transform: translate(-50%, -50%);
        }
        .preline-mobile{
            white-space: pre-line;
        }

    }
}


.ai-word {
    text-align: center;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 40%);
    width: 100%;
}

#apexis-ai {
    img {
        mask-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%);
    }
}