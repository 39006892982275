.loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: fixed;
    z-index: 999;
}


@media only screen and (max-width: 768px) {
    .logo-img {
        width: 25%;
    }
}

@media only screen and (min-width: 1024px) {
    .logo-img {
        width: 20%;
    }
}

.loader-wrap {
    margin: auto;
    text-align: center;
}

.spinner {
    width: 60px;
    height: 60px;
    margin: auto;
    border: 8px solid;
    border-color: #532482 transparent #532482 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes slideInUp {
0% {
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
}
100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}
}