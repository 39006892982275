@import '_theme-variables.scss';

#technology {
  #blueprint {
    ul {
      padding-top: 20px;
      --col-gap: 2rem;
      --row-gap: 4rem;
      --line-w: 0.25rem;
      display: grid;
      grid-template-columns: var(--line-w) 1fr;
      grid-auto-columns: max-content;
      column-gap: var(--col-gap);
      list-style: none;
      margin-inline: auto;
    }

    /* line */
    ul::before {
      content: '';
      grid-column: 1;
      grid-row: 1 / span 20;
      background: rgb(225, 225, 225);
      border-radius: calc(var(--line-w) / 2);
    }

    /* columns*/

    /* row gaps */
    ul li:not(:last-child) {
      margin-bottom: var(--row-gap);
    }

    /* card */
    ul li {
      grid-column: 2;
      --inlineP: 1.5rem;
      margin-inline: var(--inlineP);
      grid-row: span 2;
      display: grid;
      grid-template-rows: min-content min-content min-content;
    }

    /* date */
    ul li .date {
      --dateH: 3rem;
      height: 3rem;
      margin-inline: calc(1.5rem * -1);

      text-align: center;
      background-color: #532482;

      color: white;
      font-size: 16px;
      font-weight: 700;

      display: grid;
      place-content: center;
      position: relative;

      border-radius: calc(3rem / 2) 0 0 calc(3rem / 2);
    }

    /* date flap */
    ul li .date::before {
      content: '';
      width: var(--inlineP);
      aspect-ratio: 1;
      background-color: #532482;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
      position: absolute;
      top: 100%;

      clip-path: polygon(0 0, 100% 0, 0 100%);
      right: 0;
    }

    /* circle */
    ul li .date::after {
      content: '';
      position: absolute;
      width: 2rem;
      aspect-ratio: 1;
      background: rgba(245, 245, 245);
      border: 0.3rem solid #532482;
      border-radius: 50%;
      top: 50%;

      transform: translate(50%, -50%);
      right: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }

    /* title descr */
    ul li .title,
    ul li .descr {
      background: rgba(245, 245, 245);
      position: relative;
      padding-inline: 1.5rem;
    }

    ul li .title {
      overflow: hidden;
      padding-block-start: 1.5rem;
      padding-block-end: 1rem;
      font-weight: 700;
    }

    ul li .descr {
      padding-block-end: 1.5rem;
      font-weight: 300;
    }

    /* shadows */
    // ul li .title::before,
    // ul li .descr::before {
    //   content: "";
    //   position: absolute;
    //   width: 90%;
    //   height: 0.5rem;
    //   background: rgba(0, 0, 0, 0.5);
    //   left: 50%;
    //   border-radius: 50%;
    //   filter: blur(4px);
    //   transform: translate(-50%, 50%);
    // }

    ul li .title::before {
      bottom: calc(100% + 0.125rem);
    }

    ul li .descr::before {
      z-index: -1;
      bottom: 0.25rem;
    }

    @media (min-width: 50rem) {
      ul {
        grid-template-columns: 1fr var(--line-w) 1fr;
      }

      ul::before {
        grid-column: 2;
      }

      ul li:nth-child(odd) {
        grid-column: 1;
      }

      ul li:nth-child(even) {
        grid-column: 3;
      }

      /* start second card */
      ul li:nth-child(2) {
        grid-row: 2/4;
      }

      ul li:nth-child(odd) .date::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
      }

      ul li:nth-child(odd) .date::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
      }

      ul li:nth-child(odd) .date {
        border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
      }
    }

    .credits {
      margin-top: 1rem;
      text-align: right;
    }

    .credits a {
      color: var(--color);
    }

    .section {
      padding: 50px 0;
    }

    .section .container {
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
    }

    .section h1 {
      font-size: 2.5rem;
      line-height: 1.25;
    }

    .section h2 {
      font-size: 1.3rem;
    }

    /* TIMELINE
    –––––––––––––––––––––––––––––––––––––––––––––––––– */

    .timeline {
      position: relative;
      white-space: nowrap;
      // max-width: 1400px;
      // padding: 0 10px;
      margin: 0 auto;
      display: grid;
      // grid-template-columns: 320px auto;
      grid-gap: 20px;
    }

    // .timeline::before,
    // .timeline::after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     bottom: 30px;
    //     width: 100px;
    //     z-index: 2;
    // }

    // .timeline::after {
    //     right: 0;
    //     background: linear-gradient(270deg, var(--timeline-gradient));
    // }

    // .timeline::before {
    //     // left: 340px;
    //     background: linear-gradient(90deg, var(--timeline-gradient));
    // }

    .timeline .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 40px;
      color: $white;
      background: var(--midnight-green);
      white-space: normal;
      border-radius: 10px;
    }

    .timeline .info img {
      margin-bottom: 20px;
    }

    .timeline .info p {
      margin-top: 10px;
      color: var(--crystal);
    }

    .timeline .info a {
      text-decoration: none;
    }

    .timeline ol::-webkit-scrollbar {
      height: 12px;
    }

    .timeline ol::-webkit-scrollbar-thumb,
    .timeline ol::-webkit-scrollbar-track {
      border-radius: 92px;
    }

    .timeline ol::-webkit-scrollbar-thumb {
      background: $purple;
    }

    .timeline ol::-webkit-scrollbar-track {
      background: $black;
    }

    .timeline ol {
      font-size: 0;
      padding: 210px 0;
      transition: all 1s;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-color: $white $purple;
    }

    .timeline ol li {
      position: relative;
      display: inline-block;
      list-style-type: none;
      width: 160px;
      height: 5px;
      background: $general;
      scroll-snap-align: start;
    }

    // .timeline ol li:last-child {
    //     width: 340px;
    // }

    .timeline ol li:not(:first-child) {
      margin-left: 14px;
    }

    .timeline ol li::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      bottom: 0;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      border-radius: 50%;
      background: $black;
      z-index: 1;
    }

    .timeline ol li div {
      position: absolute;
      left: 100px;
      width: 340px;
      padding: 15px;
      font-size: 1rem;
      white-space: normal;
      color: #111;
      background: #fff;
      border-radius: 0 10px 10px 10px;
    }

    .timeline ol li div::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
    }

    .timeline ol li:nth-child(odd) div {
      top: -16px;
      transform: translateY(-100%);
      border-radius: 10px 10px 10px 0;
    }

    .timeline ol li:nth-child(odd) div::before {
      top: 100%;
      border-width: 8px 8px 0 0;
      border-color: $white transparent transparent transparent;
    }

    .timeline ol li:nth-child(even) div {
      top: calc(100% + 16px);
    }

    .timeline ol li:nth-child(even) div::before {
      top: -8px;
      border-width: 8px 0 0 8px;
      border-color: transparent transparent transparent $white;
    }

    .timeline time {
      display: block;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      color: $white;
      background: $purple;
      width: fit-content;
      padding: 5px 18px;
      border-radius: 8px;
    }

    /* GENERAL MEDIA QUERIES
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    @media screen and (max-width: 800px) {
      .timeline {
        display: block;
      }

      .timeline::before,
      .timeline::after {
        width: 50px;
      }

      .timeline::before {
        left: 0;
      }

      .timeline .info {
        display: none;
      }
    }
  }
}
