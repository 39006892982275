:root {
    --white: #fff;
    --black: #323135;
    --crystal: #a8dadd;
    --columbia-blue: #cee9e4;
    --midnight-green: #01565b;
    --yellow: #e5f33d;
    --timeline-gradient: rgba(206, 233, 228, 1) 0%, rgba(206, 233, 228, 1) 50%, rgba(206, 233, 228, 0) 100%;
}

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$black_more: #000;
$general: #a2a2a2;
$light_gray: #f5f5f7;
$black_soft: #333;
$black_col: #2e333c;
$red: #ff2d00;
$red_more: #fc202c;
$yellow: #ffc83d;
$purple: #532482;
$orange: #fd621a;
$dark_blue: #051025;
$light_blue: #1539ff;
$blue_soft: #d7f5ff;